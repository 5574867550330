import $ from "jquery";

/**
 * Created by Mateusz Chuchro [Squiz Poland] on 05/07/16.
 */
export function sqAccordion(params) {
    var settings = $.extend(
        {
            //##Core
            container: ".accordion",
            item: ".accordion__item",
            itemHead: ".accordion__item__head",
            itemLink: ".accordion__item__link",
            itemContent: ".accordion__item__content",
            active: ".active",
            activeAll: false,
        },
        params
    );

    /**
     *  @name generateStruct
     *  @params none
     *  @desc Find each element with class $itemHead and wrap text with anchor
     */
    function generateStruct() {
        $.each($(settings.container), function (keyCont) {
            let $this = $(this);

            $this.find(settings.item).each(function (key) {
                $this = $(this);

                $this.find(settings.itemHead).each(function () {
                    let $this = $(this),
                        insideText = $this.text();

                    $this.html(
                        "<a class=" +
                            settings.itemLink.split(".").join("") +
                            " aria-controls=collapsible-" +
                            keyCont +
                            key +
                            ' aria-expanded="false" href="#"><span>' +
                            insideText +
                            "</span></a>"
                    );
                });

                $this.find(settings.itemContent).each(function () {
                    let $this = $(this);

                    $this.attr("aria-hidden", "true");
                    $this.attr("id", "collapsible-" + keyCont + key);
                });

                // $this.find(settings.itemContent).find('a').each(function() {
                //     $(this).attr('tabindex', '-1');
                // });
            });
        });
    }

    /**
     * @name clickHandler
     * @params none
     * @desc Detect click event on the accordion and show content
     */
    function clickHandler() {
        $(settings.item + " " + settings.itemHead + " a").on("click", function (
            e
        ) {
            e.preventDefault();

            let $this = $(this),
                parent = $this.parents(settings.container),
                active = settings.active.split(".").join("");

            //  parent.siblings().find(settings.item).removeClass(active);
            parent
                .siblings()
                .find(settings.itemLink)
                .attr("aria-expanded", "false");
            parent
                .siblings()
                .find(settings.itemContent)
                .attr("aria-hidden", "true");

            if (
                typeof $this
                    .closest(settings.container)
                    .attr("data-active-all") !== "undefined"
            ) {
                settings.activeAll = /true/i.test(
                    $this.closest(settings.container).attr("data-active-all")
                );
            }

            if (settings.activeAll === false) {
                $this.closest(settings.item).siblings().removeClass(active);
                $this
                    .closest(settings.item)
                    .siblings()
                    .find(settings.itemLink)
                    .attr("aria-expanded", "false");
                $this
                    .closest(settings.item)
                    .siblings()
                    .find(settings.itemContent)
                    .attr("aria-hidden", "true");
            }

            $this.closest(settings.item).hasClass(active)
                ? $this.attr("aria-expanded", "false")
                : $this.attr("aria-expanded", "true");
            $this.closest(settings.item).hasClass(active)
                ? $this
                      .closest(settings.item)
                      .find(settings.itemContent)
                      .attr("aria-hidden", "true")
                : $this
                      .closest(settings.item)
                      .find(settings.itemContent)
                      .attr("aria-hidden", "false");
            // $this.closest(settings.item).hasClass(active)
            //     ? $this
            //         .closest(settings.item)
            //         .find(settings.itemContent)
            //         .attr('tabindex', "-1")
            //         .find('a')
            //         .each(function() {
            //             $(this).attr('tabindex', "-1");
            //         })
            //     : $this
            //         .closest(settings.item)
            //         .find(settings.itemContent)
            //         .attr('tabindex', "0")
            //         .find('a')
            //         .each(function() {
            //             $(this).attr('tabindex', "0");
            //         });
            $this.closest(settings.item).hasClass(active)
                ? $this.closest(settings.item).removeClass(active)
                : $this.closest(settings.item).addClass(active);
        });

        $(document).on("click focus keyup", function (ev) {
            if (!$(ev.target).is(settings.container + " *")) {
                $(settings.container)
                    .find(settings.active)
                    .removeClass("active");
                $(settings.container)
                    .find(settings.itemLink)
                    .attr("aria-expanded", "false");
                $(settings.container)
                    .find(settings.itemContent)
                    .attr("aria-hidden", "true");
                // $(settings.container)
                //     .find(settings.itemContent)
                //     .attr("tabindex", "-1")
                //     .find("a")
                //     .each(function () {
                //         $(this).attr("tabindex", "-1");
                //     });
            }
        });
    }

    //#Init
    generateStruct();
    clickHandler();
}
