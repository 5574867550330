import $ from 'jquery';

const section = '.js-quick-view',
      linkQuickView = '.js-quick-link',
      buttonClose = '.quick-view__close',
      activeClass = 'opened',
      bodyClass = 'opened-view';

function init() {

    bind();
}

function showQuickView(targetId) {

    if ( $(section).length ) {
        $(targetId).addClass(activeClass);
        $('body').addClass(bodyClass);
        $(targetId).attr('tabindex','0').focus();
    }
}

function hideQuickView() {

    if ( $(section).length ) {

        $(section).removeClass(activeClass);
        $('body').removeClass(bodyClass);
        $(section).attr('tabindex','-1');
    }
}

function bind() {

    // event for clicked
    $(document).on('click.quickview',linkQuickView, function(e) {
        let targetId = $(this).attr('data-target');
        e.preventDefault();
        
        // Hide all existing quickviews to prevent duplicate 
        // trays from appearing
        hideQuickView();
        showQuickView(targetId);
    });

    $(document).on('click.quickview',buttonClose, function(e) {
        e.preventDefault();

        hideQuickView();
    });

    $(document).on('click.quickview', function (ev) {
        if (!$(ev.target).is(linkQuickView) && !$(ev.target).is(section + ' *')) {

            hideQuickView();
        }
    });
}

init();

