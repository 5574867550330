import $ from 'jquery';

const section = '.js-tabs',
      buttonTabMenu = '.tabs__menu-btn',
      buttonTabMenuTemplate = `<button class="tabs__menu-btn" aria-haspopup="true" aria-expanded="false">
                                <span class="sr-only">Show/hide menu</span>
                               </button>`;

function init() {

    // for resize window / mobile / desktop
    window.fb.fn.checkMobileListener(window.fb.vars.mobileBreakpoint, mobileTabs,desktopTabs);

    bind();

}

function mobileTabs() {

    $(section).each( function() {
        let listTab = $(this).find('ul');

        $(this).removeClass('open');
        $(this).find('.active').parent().css('order', '-1');
        $(buttonTabMenuTemplate).prependTo(this);

        // accessibility
        listTab.attr('role','menu').find('a').attr('role','menuitem').attr('tabindex','-1');
    });
}

function desktopTabs() {

    $(section).each( function() {
        let listTab = $(this).find('ul');

        // accessibility
        $(this).find('.active').parent().css('order','');
        $(this).find(buttonTabMenu).remove();
        listTab.find('a').removeAttr('role').removeAttr('tabindex');
    });
}

function bind() {

    // event for clicked button tab menu
    $(section).on('click',buttonTabMenu, function(e) {
        e.preventDefault();

        if ( window.fb.fn.checkMobile(window.fb.vars.mobileBreakpoint) ) {
            let sectionTab = $(this).closest(section),
                listTab = sectionTab.find('ul');

            sectionTab.toggleClass('open');
            sectionTab.hasClass('open') ? listTab.find('a').not('.active').attr('tabindex','0')
                :  listTab.find('a').attr('tabindex','-1');

            // accessibility
            sectionTab.find(buttonTabMenu).attr('aria-expanded',sectionTab.hasClass('open'));
        }
    });

    $(document).on('click focus keyup', function (ev) {
        if (!$(ev.target).is(section + " *") && window.fb.fn.checkMobile(window.fb.vars.mobileBreakpoint) ) {
            // accessibility
            $(section).removeClass('open').find('a').attr('tabindex','-1');
            $(buttonTabMenu).attr('aria-expanded','false');
        }
    });
}

init();

