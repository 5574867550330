import $ from 'jquery';

const toggleBtn = '.btn-toggle';

function init() {

    $(toggleBtn).each( function() {
        toggleElements($(this));
    });

    bind();
}

function bind() {

    $(toggleBtn).on('click', function() {
        toggleElements($(this));
    });
}


function toggleElements(btn) {

    let showEl = typeof btn.attr('data-show') !== 'undefined' ? parseInt(btn.attr('data-show')) : 3,
        moreText = typeof btn.attr('data-more') !== 'undefined' ? btn.attr('data-more') : 'More',
        lessText = typeof btn.attr('data-less') !== 'undefined' ? btn.attr('data-less') : 'Less',
        listEl = btn.prev('ul'),
        listLength = listEl.find('li').length;

    btn.blur();

    if ( listEl.length ) {

        switch(true) {
            case listLength <= showEl:
                btn.hide();
                break;
            case listEl.find('li').not(':hidden').length === showEl:
                listEl.find('li').slideDown();
                btn.children().first().text(lessText).end().last().text('');
                break;
            default:
                listEl.find('li').slice(showEl,listEl.find('li').length).slideUp();
                btn.children().first().text(moreText).end().last().text('(' + ( listLength - showEl ) + ')');
        }
    }
}

init();
