import $ from 'jquery';

const section = '.js-module-filter',
    btnFilter = '.module-filter__title',
    btnMenu = '.module-filter__item-title',
    item = '.module-filter__item',
    activeClass = 'active',
    selectorCompare = '.js-module-compare',
    btnCompare = '.btn__compare',
    twoCol = '.content-wrapper--col';

function init() {
    // check mobile window
    window.fb.fn.checkMobileListener(window.fb.vars.mobileBreakpoint, mobileFilter,desktopFilter);

    bind();
}

function bind() {

    $(section).on('click keypress', btnMenu, function(e) {
        e.preventDefault();

        // for mobile only
        window.fb.fn.checkMobile(window.fb.vars.mobileBreakpoint) &&
        $(this).toggleClass(activeClass).attr('aria-expanded',$(this).hasClass(activeClass)).next().slideToggle();

        // for desktop and twoCol class
        !window.fb.fn.checkMobile(window.fb.vars.mobileBreakpoint) && $(twoCol).length &&
        $(this).toggleClass('open').attr('aria-expanded',$(this).hasClass('open')).next().slideToggle();

    });
    // open/close mobile filter
    $(section).on('click', btnFilter, function(e) {
        e.preventDefault();

        $(this).hasClass(activeClass) && $(btnMenu).removeClass(activeClass).next().hide();

        window.fb.fn.checkMobile(window.fb.vars.mobileBreakpoint) &&
        $(this).toggleClass(activeClass).attr('aria-expanded',$(this).hasClass(activeClass)).next().slideToggle();

    });
    // keypress enter, open/close filter / keyboard accessibility
    $(section).on('keypress',function(e) {

        e.which === 13 && !window.fb.fn.checkMobile(window.fb.vars.mobileBreakpoint) && $(twoCol).length &&
        $(this).toggleClass('open').attr('aria-expanded',$(this).hasClass('open')).next().slideToggle();

    });
    // keyboard accessibility
    $(section).on('mouseenter focusin', item, function() {

        !$(twoCol).length && $(this).find(btnMenu).attr('aria-expanded',true);
    });
    // keyboard accessibility
    $(section).on('mouseleave focusout', item, function() {

        !$(twoCol).length && $(this).find(btnMenu).attr('aria-expanded',false);
    });

    $(section).on('click', btnCompare, function(e) {
        e.preventDefault();

        // open compare section
        $(selectorCompare).slideDown('slow');
    });
}

function mobileFilter() {

    // add aria attributes
    $(btnFilter).attr({'role':'button','aria-haspopup':true,'aria-expanded':false});
    $(btnMenu).attr('role','button');
}

function desktopFilter() {

    // remove aria attributes
    $(btnMenu).removeClass(activeClass).removeAttr('role').parent().blur().end().next().removeAttr('style');
    $(btnFilter).removeClass(activeClass).removeAttr('role aria-haspopup aria-expanded').next().removeAttr('style');
}

init();
