
import $ from 'jquery';

const section = '.js-module-search',
      searchInput = '.module-search__query',
      targetMuationEl = '.tt-menu',
      observeClass = 'tt-open',
      overlayClass= 'overlay';

function init() {

    bind();
}

function overlayMask() {
    let targetMutation = document.querySelectorAll(targetMuationEl);

    for (var i = 0; i < targetMutation.length; i++) {

        // create an observer instance
        var observer = new MutationObserver(function(mutations) {

            mutations.forEach(function(mutation) {

                if (mutation.attributeName === 'class') {
                    var value = $(mutation.target).hasClass(observeClass),
                        parent = $(mutation.target).closest(section);

                    value ? parent.addClass(overlayClass) : parent.removeClass(overlayClass);
                }

            });
        });

        // configuration of the observer
        const config = { attributes: true };

        // pass in the target node, as well as the observer options
        observer.observe(targetMutation[i], config);
    }

}

function bind() {

    overlayMask();

    $(section).on('focus', searchInput, function(e) {
        e.preventDefault();

        // for mobile only
        window.fb.fn.checkMobile(window.fb.vars.mobileBreakpoint) &&
        $('html, body').animate({ scrollTop: ($(this).offset().top - 10) }, '500');
        return false;

    });


}

init();
