import $ from 'jquery';
import { moveHtmlEl } from "../../../content/contact/js/global";

const section = '.js-search-results',
    btn = '.search-results__icon',
    activeClass = 'active',
    viewList = "search-results__list--list-view",
    targetSelector = '.search-results__list';

function changeView() {

 //   $('.module-az').length && $(section).off('click.view');

    !$('.module-az').length && ( $(section).on('click.view', btn, function(e) {
        e.preventDefault();

        if ( $(this).hasClass(activeClass) ) {
            return 0;
        }

        let listResults = $(this).closest(section).find(targetSelector);

        $(this).addClass(activeClass).siblings().removeClass(activeClass);
        listResults.hasClass(viewList) ? listResults.removeClass(viewList) : listResults.addClass(viewList);

        moveHtmlEl();
    }));
}

changeView();



