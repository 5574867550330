import { sqAccordion } from '../../sqPlugins/sqAccordion/js/jquery-sqAccordion.js';

sqAccordion({
    //##Core
    container: '.module-accordion__list',
    item: '.module-accordion__item',
    itemHead: '.module-accordion__head',
    itemLink: '.module-accordion__link',
    itemContent: '.module-accordion__content',
    active: '.active',
    activeAll: false,
    activeAllData: 'data-active-all',
    speed: 500,
});
