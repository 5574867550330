(function(){
    'use strict';

    // Global variables
    var fb = {
        'fn': {},
        'vars': {
            mobileBreakpoint: 1024
        }
    };

    // Functions
    fb.fn.sampleFunction = function() {

    };

    fb.fn.checkMobile = function(size) {
        let viewport = window.matchMedia('screen and (max-width:768px)');

        if ( size !== undefined && parseInt(size) !== 'NaN' ) {
            viewport = window.matchMedia('screen and (max-width:' + size + 'px)');
        }

        if ( viewport.matches ) {
            return true;
        }

        return false;
    };

    fb.fn.checkMobileListener = function(size, callback, callback2) {
        let viewport = window.matchMedia('screen and (max-width:768px)');

        if (size !== undefined && parseInt(size) !== 'NaN') {
            viewport = window.matchMedia('screen and (max-width:' + size + 'px)');
        }

        if (typeof callback === 'function' && typeof callback2 === 'function') {
            if (viewport.matches) {
                callback();
            } else {
                callback2();
            }
        } else {
            console.log('callback is not a function');
        }

        viewport.addListener(function(mq) {
            if (typeof callback === 'function' && typeof callback2 === 'function') {
                if (mq.matches) {
                    callback();
                } else {
                    callback2();
                }
            } else {
                console.log('callback is not a function');
            }
        });
    };

    // Make fb variable available to the console for debugging
    window.fb = fb;
}());
