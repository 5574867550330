import $ from 'jquery';

/**
 * functions for management hamburger button and mobile menu
 */

let hamburger = {
    targetButton: '.header-hamburger__button',

    init: function(){
        let self = this;
        self.bind();
    },

    bind: function(){
        let self = this;


        $(self.targetButton).on('click',function (e) {
            e.preventDefault();

            $( this ).toggleClass( 'is-active' );
        });
    }
}

hamburger.init();

