import $ from 'jquery';

const dropdownClass = '.js-dropdown-list__link',
      dropdownList = '.dropdown-list__list';

function init() {

    bind();
}

function bind() {

    $(dropdownClass).on('click.dropdown', function() {

        $(this).attr('aria-expanded',$(this).next().is(':hidden'));

        if ( $(this).hasClass('opened') ) {

            $(this).removeClass('opened').next().slideUp();
        } else {

            $(dropdownClass).removeClass('opened').next().slideUp();
            $(this).addClass('opened').next().slideDown();
        }
    });

    $(dropdownClass).on('focus', function() {

        $(dropdownClass).next().slideUp().end().removeClass('opened');
    });

    $(document).on('click.dropdown', function (ev) {
        if (!$(ev.target).is('.dropdown-list') && !$(ev.target).is('.dropdown-list ' + ' *')) {

            $(dropdownList).slideUp();
            $(dropdownClass).attr('aria-expanded','false').removeClass('opened');
        }
    });
}


init();
