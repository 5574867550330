import $ from 'jquery';

const section = '.js-module-compare',
      selectorBar = '.module-compare__close';

function init() {


    $(section).on('click',selectorBar, function(e) {
        e.preventDefault();

        $(this).closest(section).slideUp('slow');
    });



            $('table.module-compare__list,.search-results__table').each(function(i, table) {
                var self = $(table),
                    arrTh = [],
                    attr = '',
                    thVertical = 0,
                    index;

                self.find('th').each(function(j, th) {
                    arrTh.push(th.innerText + attr);
                });

                self.find('tr').each(function(k, tr) {
                    thVertical = $(tr).find('th').length ? 1 : 0;
                    $(tr)
                        .find('td')
                        .each(function(z, td) {
                            index = thVertical ? k : z;

                            if ( arrTh[index] ) {
                                $(td).addClass('datath');
                                $(td).attr('data-th', arrTh[index]);
                            }
                        });
                });
            });

}

init();

