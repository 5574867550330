import $ from 'jquery';

const section = '.js-contact',
    moveEl = '.contact__item--event-time',
    viewList = '.search-results__list--list-view',
    targetEL = '.search-results__item--event',
    descEl = '.search-results__desc',
    titleEl = '.search-results__title',
    subTitleEl = '.search-results__sub-title';

function init() {

    moveHtmlEl();
}

export function moveHtmlEl() {

    $(targetEL).each(function() {
        let desc =  $(this).find(descEl),
            title = $(this).find(titleEl),
            subTitle = $(this).find(subTitleEl),
            move = $(this).find(moveEl);

            if ( $(viewList).length ) {
                $(this).find(section).prepend($(this).find(move));
            } else {

                switch(true) {
                    case desc.length:
                        desc.before(move);
                        break;
                    case subTitle.length:
                        subTitle.after(move);
                        break;
                    default:
                       title.after(move);
                }
            }
    });
}

init();
